export const useAuthStore = defineStore('auth', {
  state: () => ({
    chosenPriceId: null,
    chosenProductId: null,
  }),
  actions: {
    setChosenProductId(productId) {
      this.chosenProductId = productId
    },
    setChosenPriceId(priceId) {
      this.chosenPriceId = priceId
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
